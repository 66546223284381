import request from '@/utils/request'

//城市
export function getCityAll() {
  return request({
    url: '/china/getCityAll',
    method: 'get'
  })
}


//内容管理 - 编辑 -->   单文件上传
export function apiUpload(data) {
  return request({
    url: '/public/apiUpload',
    method: 'POST',
    data
  })
}

// 查询
export function getCode(params) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params
  })
}
