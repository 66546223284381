import RouterView from '@/components/RouterView'

const menuList = [{
    path: '/cockpit/index',
    component: RouterView,
    redirect: '/cockpit/index',
    meta: {
      title: '工作台',
      isMenu: true
    },
    children: [{
      name: 'cockpit',
      path: '/cockpit/index',
      component: () => import('../views/cockpit/index.vue'),
      meta: {
        title: '工作台',
        isMenu: true
      }
    }]
  },
  {
    path: '/CityPartner/index',
    component: RouterView,
    redirect: '/CityPartner/index',
    meta: {
      title: '城市合伙人',
      isMenu: true
    },
    children: [{
      name: 'CityPartner',
      path: '/CityPartner/index',
      component: () => import('../views/CityPartner/index.vue'),
      meta: {
        title: '城市合伙人',
        isMenu: true
      }
    }]
  },
  {
    path: '/operate',
    name: 'Operate',
    component: RouterView,
    redirect: '/user/list',
    meta: {
      title: '运营管理',
      isMenu: true
    },
    children: [{
        meta: {
          title: '用户管理',
          isMenu: true
        },
        name: 'User',
        path: '/user/list',
        component: () => import('../views/operate/user.vue')
      },
      {
        meta: {
          title: '客户管理',
          isMenu: true
        },
        name: 'custom',
        path: '/operate/custom',
        component: () => import('../views/operate/custom.vue')
      },

      {
        meta: {
          title: '客户订单',
          isMenu: true
        },
        name: 'customorder',
        path: '/operate/customorder',
        component: () => import('../views/operate/components/customorder.vue')
      },
      {
        meta: {
          title: '订单管理',
          isMenu: true
        },
        name: 'order',
        path: '/operate/order',
        component: () => import('../views/operate/order.vue')
      },
      {
        name: 'addenterprise',
        path: '/customer/addenterprise',
        component: () => import('../views/operate/components/addenterprise'),
        meta: {
          title: '编辑基本资料',
          isMenu: true
        }
      },
      {
        name: 'CorrelateUser',
        path: '/customer/CorrelateUser',
        component: () => import('../views/operate/components/accountManagement'),
        meta: {
          title: '账号管理',
          isMenu: true
        }
      },
      {
        name: 'Records',
        path: '/customer/records',
        component: () => import('../views/operate/components/records'),
        meta: {
          title: '诊断记录',
          isMenu: true
        }
      },
      {
        name: 'Recordinfo',
        path: '/customer/recordinfo',
        component: () => import('../views/operate/components/recordinfo'),
        meta: {
          title: '诊断详情',
          isMenu: true
        }
      },
      {
        name: 'cpmpanyinfo',
        path: '/customer/cpmpanyinfo',
        component: () => import('../views/operate/components/cpmpanyinfo'),
        meta: {
          title: '详情',
          isMenu: true
        }
      }
    ]
  },
  {
    path: '/service',
    name: 'Service',
    component: RouterView,
    redirect: '/service/diagnosis',
    meta: {
      title: '服务管理',
      isMenu: true
    },
    children: [{
        name: 'diagnosis',
        path: '/service/diagnosis',
        component: () => import('../views/servicesmage/diagnosis'),
        meta: {
          title: '诊断服务',
          isMenu: true
        }
      },
      {
        name: 'consultant',
        path: '/service/consultant',
        component: () => import('../views/servicesmage/consultant'),
        meta: {
          title: '顾问服务',
          isMenu: true
        }
      },
      {
        name: 'onlinedetail',
        path: '/service/onlinedetail',
        component: () => import('../views/servicesmage/component/onlinedetail'),
        meta: {
          title: '详情',
          isMenu: true
        }
      },
      {
        name: 'content',
        path: '/service/content',
        component: () => import('../views/servicesmage/content'),
        meta: {
          title: '信息服务',
          isMenu: true
        }
      },
      {
        name: 'addcontent',
        path: '/service/addcontent',
        component: () => import('../views/servicesmage/content/components/addcontent.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'listUserCheckPage',
        path: '/service/listUserCheckPage',
        component: () => import('../views/servicesmage/content/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'listUserSharePage',
        path: '/service/listUserSharePage',
        component: () => import('../views/servicesmage/content/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },

      {
        name: 'activity',
        path: '/service/activity',
        component: () => import('../views/servicesmage/activity'),
        meta: {
          title: '活动服务',
          isMenu: true
        }
      },
      {
        name: 'addactivity',
        path: '/service/addactivity',
        component: () => import('../views/servicesmage/activity/components/editActivity.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'checkCount',
        path: '/service/checkCount',
        component: () => import('../views/servicesmage/activity/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'shareCount',
        path: '/service/shareCount',
        component: () => import('../views/servicesmage/activity/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },

      {
        name: 'accountManagement',
        path: '/service/accountManagement',
        component: () => import('../views/servicesmage/activity/components/accountManagement.vue'),
        meta: {
          title: '报名管理',
          isMenu: true
        }
      },

      {
        name: 'report',
        path: '/service/report',
        component: () => import('../views/servicesmage/report'),
        meta: {
          title: '报告服务',
          isMenu: true
        }
      },
      {
        name: 'reportcheck',
        path: '/service/reportcheck',
        component: () => import('../views/servicesmage/report/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'reportshare',
        path: '/service/reportcheck',
        component: () => import('../views/servicesmage/report/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        name: 'downloadCount',
        path: '/service/downloadCount',
        component: () => import('../views/servicesmage/report/components/downloadCount.vue'),
        meta: {
          title: '下载人数',
          isMenu: true
        }
      },

      {
        name: 'expert',
        path: '/service/expert',
        component: () => import('../views/servicesmage/expert'),
        meta: {
          title: '专家咨询',
          isMenu: true
        }
      },
      {
        name: 'expertCheckCount',
        path: '/service/expert/checkCount',
        component: () => import('../views/servicesmage/expert/component/checkCount'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'expertSharing',
        path: '/service/expert/sharing',
        component: () => import('../views/servicesmage/expert/component/sharing'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        name: 'consultantsNumber',
        path: '/service/expert/consultantsNumber',
        component: () => import('../views/servicesmage/expert/component/consultantsNumber.vue'),
        meta: {
          title: '咨询人数',
          isMenu: true
        }
      },
      {
        name: 'addexpert',
        path: '/service/addexpert',
        component: () => import('../views/servicesmage/expert/component/addexpert'),
        meta: {
          title: '编辑专家',
          isMenu: true
        }
      }

      // {
      //   name: 'consultant',
      //   path: '/service/consultant',
      //   component: () => import('../views/servicesmage/consultant'),
      //   meta: {
      //     title: '顾问服务',
      //     isMenu: true
      //   }
      // },
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: RouterView,
    redirect: '/system/user',
    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [{
        name: 'sysuser',
        path: '/system/user',
        component: () => import('../views/system/user'),
        meta: {
          title: '系统用户',
          isMenu: true
        }
      },
      {
        name: 'authority',
        path: '/system/authority',
        component: () => import('../views/system/authority'),
        meta: {
          title: '权限管理',
          isMenu: true
        }
      },
      {
        name: 'log',
        path: '/system/log',
        component: () => import('../views/system/log'),
        meta: {
          title: '日志管理',
          isMenu: true
        }
      }
    ]
  }
]

export default menuList
