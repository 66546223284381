import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/theme/index.css'
import 'normalize.css'
import './assets/css/bts.less'
import '../static/font/iconfont.css'
import {
  hasPermission
} from '@/utils/permission.js'
//单个图片上传
import ImgBigUpload from '@/components/ImgBigUpload'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('ImgBigUpload', ImgBigUpload)
Vue.prototype.$hasPerms = hasPermission

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker);

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 适配
// window.onload = window.onresize = function () {
//   // 获取网页大小
//   let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//   // 判断窗口宽度是否小于等于笔记本大小（假设笔记本大小为1200像素）
//   if (windowWidth <= 1200) {
//     // 就让页面缩放成0.7倍
//     document.body.style.zoom = 0.7
//   }
// }



Vue.filter('date', (val) => {
  if (val) {
    val = val.toString().split(' ')[0]
    return val
  } else {
    return ''
  }
})

Vue.filter('time', (val) => {
  if (val) {
    val = val.toString().split(' ')[1]
    return val
  } else {
    return ''
  }
})


new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
